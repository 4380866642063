
import { defineComponent } from 'vue'

const partners = [
  { img: require('../assets/network/bwkg-Badenwuerttemberg.jpg') },
  { img: require('../assets/network/Deutscher-Staedttag.svg') },
  { img: require('../assets/network/hhu_logo.png') },
  { img: require('../assets/network/KG-Brandenburg.png') },
  { img: require('../assets/network/KG-Bremen.png') },
  { img: require('../assets/network/KG-NRW.svg') },
  { img: require('../assets/network/KG-Thueringen.png') },
  { img: require('../assets/network/KGS_Logo_web_2020.png') },
  { img: require('../assets/network/kgst-logo.jpg') },
  { img: require('../assets/network/Kinderklinik-Muenchen.svg') },
  { img: require('../assets/network/logo-de-mv-tut-gut-2021.png') },
  { img: require('../assets/network/logo-DKG.png') },
  { img: require('../assets/network/Logo-DKI.svg') },
  { img: require('../assets/network/marburger-bund.svg') },
  { img: require('../assets/network/meierhofer-logo-b.png') },
  { img: require('../assets/network/Niedersachsen.png') },
  { img: require('../assets/network/Statistisches-Bundesamt.svg') },
  {
    img: require('../assets/network/Tarifgemeinschaft-deutscher-Laender-TdL.png'),
  },
  { img: require('../assets/network/Universitaetsklinikum-Bonn.png') },
  {
    img: require('../assets/network/Universitaetsklinikum-des-Saarlandes.gif'),
  },
]

export default defineComponent({
  name: 'Netzwerk',
  components: {},
  data() {
    return {
      partners,
    }
  },
})
