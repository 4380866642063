<template>
  <div class="home">
    <h2>Netzwerk</h2>
    <div class="PartnerFrame">
      <template v-for="partner in partners" :key="partner.img">
        <div class="ImageContainer">
          <img class="Image" :src="partner.img" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const partners = [
  { img: require('../assets/network/bwkg-Badenwuerttemberg.jpg') },
  { img: require('../assets/network/Deutscher-Staedttag.svg') },
  { img: require('../assets/network/hhu_logo.png') },
  { img: require('../assets/network/KG-Brandenburg.png') },
  { img: require('../assets/network/KG-Bremen.png') },
  { img: require('../assets/network/KG-NRW.svg') },
  { img: require('../assets/network/KG-Thueringen.png') },
  { img: require('../assets/network/KGS_Logo_web_2020.png') },
  { img: require('../assets/network/kgst-logo.jpg') },
  { img: require('../assets/network/Kinderklinik-Muenchen.svg') },
  { img: require('../assets/network/logo-de-mv-tut-gut-2021.png') },
  { img: require('../assets/network/logo-DKG.png') },
  { img: require('../assets/network/Logo-DKI.svg') },
  { img: require('../assets/network/marburger-bund.svg') },
  { img: require('../assets/network/meierhofer-logo-b.png') },
  { img: require('../assets/network/Niedersachsen.png') },
  { img: require('../assets/network/Statistisches-Bundesamt.svg') },
  {
    img: require('../assets/network/Tarifgemeinschaft-deutscher-Laender-TdL.png'),
  },
  { img: require('../assets/network/Universitaetsklinikum-Bonn.png') },
  {
    img: require('../assets/network/Universitaetsklinikum-des-Saarlandes.gif'),
  },
]

export default defineComponent({
  name: 'Netzwerk',
  components: {},
  data() {
    return {
      partners,
    }
  },
})
</script>

<style scoped lang="scss">
.PartnerFrame {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.ImageContainer {
  padding: 24px;
  align-self: center;
}
.Image {
  max-width: 260px;
  max-height: 260px;

  min-width: 260px;
}
</style>
